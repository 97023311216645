/* global config, moment, Promise */

'use strict';

angular.module('managerApp').controller('AdsController', (
        $scope, $mdDialog, toast, ads, procedureTools) => {

    $scope.config = config;
    $scope.medias = [];

    $scope.showAddEditPopup = (item = {}, index) => {

        console.log('showAddEditPopup');
        console.log(item);

        $mdDialog.show({
            templateUrl: 'app/ads/item.html',
            clickOutsideToClose: false,
            locals: {
                item: item
            },
            controller: ($scope, $mdDialog, toast, item) => {

                $scope.edit = typeof item._id !== 'undefined';
                $scope.item = $scope.edit ? item : {
                    active: true
                };
                const origItem = angular.copy(item);

                $scope.onFileSelect = (files, target) => {
                    let file = files[0];
                    if (!file) {
                        return;
                    }
                    let img = document.getElementById('preview');
                    img.onload = () => {
                        $scope.$apply(function () {
                            $scope.item.picture = files[0];
                        });
                    };
                    let reader = new FileReader();
                    reader.onload = (function (_img) {
                        return function (e) {
                            _img.src = e.target.result;
                        };
                    })(img);
                    reader.readAsDataURL(file);
                };

                $scope.closeDialog = (validated = false, form = null) => {
                    if (!validated) {
                        $mdDialog.cancel();
                    } else {
                        if (!form.$valid) {
                            return;
                        }
                        if ($scope.edit) {
                            if (angular.equals($scope.item, origItem)) {
                                $mdDialog.hide();
                                return;
                            }
                        }
                        let promise, item = $scope.item;
                        let formData = new FormData();
                        for (let attr in item) {
                            if (['name', 'active', 'text', 'price',
                                'service', 'picture', 'link'].indexOf(attr) > -1)
                                if (item[attr]) {
                                    formData.append(attr, item[attr]);
                                }
                        }
                        if ($scope.edit) {
                            promise = ads.update(formData, $scope.item._id);
                        } else {
                            promise = ads.add(formData);
                        }
                        $mdDialog.hide(promise);
                    }//
                };
            }
        }).then((res) => {
            if (res) {
                toast.show('Le slide a bien été ajouté.', 'success');
                getAds();
            }
        }).catch((err) => {
            toast.show(err, 'error', true);
        });
    };

    $scope.showDeleteConfirm = (item) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            template:
                    `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="row" layout-wrap>
                        <div flex='100'>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>warning</md-icon> Suppression
                            </h3>
                            <md-divider></md-divider><br>
                        </div>
                        <div>
                            <p>Voulez-vous vraiment supprimer ce message ?</p>
                            <p>Cette opération sera exécutée immédiatement et elle est <b>irréversible</b> !</p>
                        </div>
                    </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer la suppression</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
            locals: {
                itemId: item._id
            },
            controller: ($scope, $mdDialog, toast, ads, itemId) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        ads.delete(itemId).then(() => {
                            toast.show('Le slider a bien été supprimé.', 'success');
                            getAds();
                        }).catch((err) => {
                            toast.show('Une erreur est survenu lors de la suppression de ce slider.', 'error');
                        });
                    }//
                };
            }
        });
    };

    $scope.toggleActive = (item) => {
        item.active = !item.active;
        ads.update(item, item._id).then(() => {
            toast.show('La publicité a bien été mise à jour.', 'success');
        }).catch(() => {
            toast.show('La publicité n\'a pas pu être mise à jour.', 'error');
            item.active = !item.active;
        });
    };

    $scope.getAds = () => {
        getAds();
    };

    function getAds() {
        ads.get().then((data) => {
            $scope.items = data;
            for (let i = 0; i < $scope.items.length; i++) {
                let item = $scope.items[i];
                /*item.path = setURL(item.path);
                 item.mobilePath = setURL(item.mobilePath);
                 item.procedureType = item.SliderId === 'home' ? 'Accueil' :
                 procedureTools.getProcedureName(item.SliderId);*/
                $scope.$apply();
            }
            $scope.$apply();
        }).catch((err) => {
            toast(err, 'error');
        });
    }

    function setURL(path) {
        return path.substr(0, 4) === 'http' ? path : config.websiteURL + '/' + path;
    }

    getAds();

});
